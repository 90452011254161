document.addEventListener('turboujs:load', (_event) => {
  document.querySelectorAll('.daily__reporting-form form input').forEach((element, i) => {
    element.addEventListener('change', selectChanged)
  })
})

function selectChanged(event) {
  let form = this.closest('form')
  form.submit()
}
